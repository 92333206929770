import { useAuthenticator } from '@aws-amplify/ui-react';
import { ETFNavMenuIconButton } from '@cfra-nextgen-frontend/shared';
import { topNavigationHeight } from '@cfra-nextgen-frontend/shared/src/utils/lookAndFeel';
import { Avatar, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import { Breakpoint, ThemeProvider, createTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ETFMenu } from 'components/layout/ETFMenu';
import { a11yProps } from 'components/layout/ETFTabPanel';
import { breakpointsTheme } from 'components/themes/theme';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fontFamilies } from 'utils/fonts';
import WALogo from '../../../assets/images/WALogo.svg';

const topNavItems: Record<string, { name: string; href: string }> = {
    Home: {
        name: 'Home',
        href: '/',
    },
    Research: {
        name: 'Research',
        href: '/research',
    },
};

export function TopNavigation() {
    const [currentActiveTab, setCurrentActiveTab] = useState<number | boolean>(false);
    const [showSearchInput] = useState(false);

    const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);

    const { pathname } = useLocation();
    useEffect(() => {
        const activeTabIndex = Object.values(topNavItems)
            .map((value) => value.href)
            .indexOf(`/${pathname.slice(1).split('/')[0]}`);

        setCurrentActiveTab(activeTabIndex > -1 ? activeTabIndex : false);
    }, [pathname]);

    const navigate = useNavigate();

    let theme = createTheme(breakpointsTheme);
    const isScreenDisplay = useMediaQuery('screen');

    theme = createTheme(theme, {
        components: {
            MuiAppBar: {
                defaultProps: {
                    position: isScreenDisplay ? 'sticky' : 'static',
                },
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: '#FFF',
                    },
                    root: {
                        borderBottom: 'solid #E4E5E9 1px',
                        borderTop: 'solid #3078B5 4px',
                        boxShadow: 'none',
                        zIndex: 1500,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#3078B5',
                        fontSize: 18,
                        fontFamily: fontFamilies.GraphikLight,
                        height: 48,
                        textTransform: 'uppercase',
                        width: 48,
                    },
                },
            },
            MuiContainer: {
                defaultProps: {
                    disableGutters: true,
                    maxWidth: '1366px' as Breakpoint,
                },
                styleOverrides: {
                    root: {
                        maxWidth: '1366px',
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        marginLeft: '18px',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            color: '#3078B5',
                        },
                        color: '#0B2958',
                        fontFamily: fontFamilies.GraphikMedium,
                        fontSize: '15px',
                        lineHeight: 1,
                        marginRight: '28px',
                        minWidth: '0px',
                        padding: 0,
                        paddingBottom: '25px',
                        paddingTop: '25px',
                        textTransform: 'none',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        background: '#4375BB',
                        borderRadius: '5px',
                        height: '4px',
                    },
                    root: {
                        [theme.breakpoints.down('lg')]: {
                            visibility: 'collapse',
                            width: 0,
                        },
                        paddingTop: '3px',
                    },
                },
            },
            MuiToolbar: {
                defaultProps: {
                    disableGutters: true,
                },
                styleOverrides: {
                    root: {
                        paddingLeft: '18px',
                        paddingRight: '18px',
                        display: 'flex',
                        height: topNavigationHeight,
                        justifyContent: 'space-between',
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    mr: 1,
                    noWrap: true,
                    variant: 'h6',
                },
                styleOverrides: {
                    root: {
                        color: '#3078B5',
                        fontSize: '28px',
                        fontFamily: fontFamilies.GraphikLight,
                        lineHeight: 1.2,
                        textDecoration: 'none',
                    },
                },
            },
        },
    });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentActiveTab(newValue);
    };

    const isBelowSM = useMediaQuery(theme.breakpoints.down('sm'));

    const cfraLogoStyles = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            display: showSearchInput ? 'none' : 'flex',
            position: 'absolute',
            left: '76px',
        },
    };

    const LogoImage = styled('img')(() => ({
        height: '60px',
        paddingTop: 'unset',
        paddingBottom: 'unset',
        '@media (max-width: 420px)': {
            height: 24,
            paddingTop: '22px',
            paddingBottom: '22px',
        },
    }));

    return (
        <ThemeProvider theme={theme}>
            <AppBar>
                <Container>
                    <Toolbar>
                        {/* https://stackoverflow.com/questions/69455056/override-box-component-in-createtheme/69455458#69455458 */}
                        <ETFMenu
                            menuItems={Object.keys(topNavItems).map((key) => {
                                return {
                                    itemName: topNavItems[key].name,
                                    callback: () => {
                                        navigate(topNavItems[key].href);
                                    },
                                };
                            })}>
                            <ETFNavMenuIconButton
                                sx={{
                                    marginLeft: 0,
                                    paddingRight: '18px',
                                    [theme.breakpoints.up('lg')]: {
                                        display: 'none',
                                    },
                                }}
                            />
                        </ETFMenu>
                        <Box
                            sx={cfraLogoStyles}
                            onClick={() => {
                                navigate(topNavItems.Home.href);
                            }}>
                            <LogoImage src={WALogo} alt='CFRA Washington Analysis Logo' />
                        </Box>
                        {isBelowSM ? (
                            !showSearchInput && <div style={{ flexGrow: 1 }} />
                        ) : (
                            <div style={{ flexGrow: 1 }} />
                        )}
                        <Tabs value={currentActiveTab} onChange={handleChange} aria-label='top navigation tabs'>
                            {Object.keys(topNavItems).map((key, index) => {
                                const topNavItem = topNavItems[key];
                                return (
                                    <Tab
                                        label={topNavItem.name}
                                        key={index}
                                        {...a11yProps(index)}
                                        onClick={() => {
                                            navigate(topNavItem.href);
                                        }}
                                    />
                                );
                            })}
                        </Tabs>
                        {isBelowSM ? (
                            !showSearchInput && <div style={{ flexGrow: 1 }} />
                        ) : (
                            <div style={{ flexGrow: 1 }} />
                        )}

                        <ETFMenu
                            menuItems={[
                                {
                                    itemName: user?.username || '',
                                },
                                {
                                    itemName: 'Logout',
                                    callback: signOut,
                                },
                            ]}>
                            <Tooltip title={user?.username}>
                                <IconButton>
                                    <Avatar>{user?.username?.charAt(0)}</Avatar>
                                </IconButton>
                            </Tooltip>
                        </ETFMenu>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}
export default TopNavigation;
