import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';
import { Route, Routes } from 'react-router-dom';
import { ResearchHome } from '../components/ResearchHome';
import { TypeSearch } from '../components/TypeSearch';

export function ResearchRoutes() {
    return (
        <Routes>
            <Route element={<PageWithComponentInHeader component={<TypeSearch />} />}>
                <Route path='/' element={<ResearchHome />} />
            </Route>
        </Routes>
    );
}
