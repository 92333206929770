import { Login } from 'features/auth/components/Login';
import { HomeRoutes } from 'features/home';
import { ResearchRoutes } from 'features/research/routes/ResearchRoutes';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoutes />}>
                <Route path='/*' element={<HomeRoutes />} />
                <Route path='/research/*' element={<ResearchRoutes />} />
            </Route>
            <Route path='/login' element={<Login />} />
        </Routes>
    );
};
