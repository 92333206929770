type PageAttributes = {
    pageId: string;
    pageTags?: string[];
};

class ClientSideLogger {
    recordPageView(payload: string | PageAttributes): void {}
}

export const clientSideLogger = new ClientSideLogger();
