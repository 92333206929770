import { Autocomplete, createTheme, ThemeProvider, Stack, Avatar } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/images/SearchIcon.svg';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { breakpointsTheme } from 'components/themes/theme';
import SvgIcon from '@mui/material/SvgIcon';

export function TypeSearch() {
    const fontStyle = {
        font: fontFamilies.GraphikMedium,
        fontSize: '26px',
        color: '#0B2958',
    };

    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [avatarBackgroundColor, setAvatarBackgroundColor] = useState('lightgray');
    const [iconColor, setIconColor] = useState('gray');

    const suggestions = Array<string>();

    let theme = createTheme(breakpointsTheme);
    theme = createTheme(theme, {
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        width: 24,
                        height: 24,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        ...fontStyle,
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: { width: '88%' },
                    option: { ...fontStyle },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Stack direction='row' sx={{ paddingBottom: '50px', paddingTop: '70px' }}>
                <Autocomplete
                    sx={{ marginLeft: '3%' }}
                    disablePortal
                    options={suggestions}
                    renderInput={(params) => (
                        <TextField {...params} placeholder='Search by Keyword or Phrase' variant='standard' />
                    )}
                    onInputChange={(event, value) => {
                        setAvatarBackgroundColor(value.length > 0 ? 'darkgray' : 'lightgray');
                        setIconColor(value.length > 0 ? 'blue' : 'gray');
                    }}
                    onChange={(event, value) => {
                        setSelectedKeyword(value === null ? '' : value);
                    }}
                    freeSolo
                    forcePopupIcon={false}
                    autoComplete
                    clearOnEscape
                    autoHighlight
                    clearIcon={''}
                />
                <Avatar
                    sx={{ backgroundColor: avatarBackgroundColor, cursor: 'pointer', marginLeft: '-3%' }}
                    variant='circular'
                    onClick={() => {
                        console.log(selectedKeyword);
                    }}>
                    <SvgIcon htmlColor={iconColor} viewBox='0 0 40 40'>
                        <SearchIcon />
                    </SvgIcon>
                </Avatar>
            </Stack>
        </ThemeProvider>
    );
}
