import { Topic } from '../types/research';
import { ReactComponent as CannabisSVG } from 'assets/images/topics/cannabis.svg';
import { ReactComponent as DefenceSVG } from 'assets/images/topics/defense.svg';
import { ReactComponent as EnergySVG } from 'assets/images/topics/energy.svg';
import { ReactComponent as FinanceAndHousingSVG } from 'assets/images/topics/financial housing.svg';
import { ReactComponent as HealthcareSVG } from 'assets/images/topics/healthcare.svg';
import { ReactComponent as MiscSVG } from 'assets/images/topics/miscellaneous.svg';
import { ReactComponent as TechMediaSVG } from 'assets/images/topics/tech telemedia.svg';
import { ReactComponent as SpecialSVG } from 'assets/images/topics/special-situation.svg';
import { ReactComponent as MacroSVG } from 'assets/images/topics/macro taxes.svg';

export function getResearchTopics(): Topic[] {
    return [
        { label: 'Macro & Taxes', image: MacroSVG },
        { label: 'Healthcare', image: HealthcareSVG },
        { label: 'Special Situations', image: SpecialSVG },
        { label: 'Energy', image: EnergySVG },
        { label: 'Defense', image: DefenceSVG },
        { label: 'Tech & TeleMedia', image: TechMediaSVG },
        { label: 'Financials & Housing', image: FinanceAndHousingSVG },
        { label: 'Miscellaneous', image: MiscSVG },
        { label: 'Cannabis', image: CannabisSVG },
    ];
}
