import { FilePickerFormState } from '@cfra-nextgen-frontend/shared/src/components/Form/FormFilePicker';
import {
    Components,
    FilterMetadata,
    FiltersData,
    FiltersMetadata,
    Item,
    StringKeyValueItemWithData,
} from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { SectionWithChildren } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/containers/types';
import {
    combineIntoFormElementName,
    parseFormElementName,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { IMetadataFields, IViewDataFields } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { replaceAllPlaceholdersInObject } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import {
    applyFieldMetadataToValue,
    sliderApplyScaleToValue,
    sliderUnApplyScaleFromNumericValue,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import { MetadataFieldDefinition, Scale } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import {
    addPathToValue,
    getDateTimeUtcNow,
    mapValues,
    scaleCharacterToNumber,
    standardDateFormat,
} from '@cfra-nextgen-frontend/shared/src/utils';
import dayjs, { Dayjs } from 'dayjs';
import { invert, isEqual, merge, uniq } from 'lodash';
import { ChipItem } from './types';

export function splitArrayByLevel(input: SectionWithChildren[]): SectionWithChildren[][] {
    const result: SectionWithChildren[][] = [];
    let currentArray: SectionWithChildren[] = [];

    input.forEach((sectionWithChildren, index) => {
        if (index === 0) {
            currentArray.push(input[0]);
            return;
        }

        if (sectionWithChildren.section.level === currentArray[0].section.level) {
            currentArray.push(sectionWithChildren);
        } else {
            result.push(currentArray);
            currentArray = [sectionWithChildren];
        }
    });
    result.push(currentArray);

    return result;
}

// sort section keys by sections order asc
export function sortSectionsWithChildrenAsc(
    sectionsWithChildren: Array<SectionWithChildren>,
): Array<SectionWithChildren> {
    return sectionsWithChildren.sort((item1, item2) => item1.section.order - item2.section.order);
}

// sort filter metadata keys by filter_metadata[x].sections[sectionKey].order asc
export function sortFilterMetadataKeysAsc(
    filtersMetadataKeys: Array<string>,
    filtersMetadata: FiltersMetadata,
    sectionKey: string,
): Array<string> {
    return filtersMetadataKeys.sort((key1, key2) => {
        const filterMetadata1Sections = filtersMetadata[key1 as keyof typeof filtersMetadata].sections;
        const filterMetadata2Sections = filtersMetadata[key2 as keyof typeof filtersMetadata].sections;
        const section1 = filterMetadata1Sections[sectionKey as keyof typeof filterMetadata1Sections];
        const section2 = filterMetadata2Sections[sectionKey as keyof typeof filterMetadata2Sections];
        return section1.order - section2.order;
    });
}

export const defaultStarsRatingLength = 5;

type PostDataValues = Array<string | number | null>;

type PostData = {
    [key: string]: { values?: PostDataValues; excluded_values?: PostDataValues };
};

type ChipsData = Record<string, ChipItem>;

const getChipItem = (
    chipLabel: string,
    chipValues: string,
    filterStateData: Array<number | string | Item | null> | boolean | undefined,
    controlID: string,
    filterSections: { [x: string]: any },
) => {
    return {
        chip: {
            label: chipLabel,
            values: chipValues,
        },
        stateData: {
            controlID: controlID,
            values: filterStateData,
            filterSections,
        },
    };
};

const addChipAdditionalLabels = (filtersData: FiltersData, key: string) => {
    let section = filtersData.filter_metadata[key].sections;

    return (
        Object.keys(section).reduce((label, sectionKey, index) => {
            return filtersData.section_mapping[sectionKey].name + (index > 0 ? ':' + label : '');
        }, '') + ': '
    );
};

type FilterTypeToArrayFiltersStateData = Partial<
    Record<
        Components,
        Array<{
            [key: string]: {
                filterStateData: Array<number | string | Item | Dayjs | boolean | null> | boolean | undefined;
                filtersMetadataKey: string;
            };
        }>
    >
>;

function getFilterTypeToArrayFiltersStateData(
    formState: {
        [x: string]: any;
    },
    filtersData: FiltersData,
): FilterTypeToArrayFiltersStateData {
    const filterTypeToArrayFiltersStateData: FilterTypeToArrayFiltersStateData = {};

    // group state of the form by components - slider, checkbox, autocomplete, etc.
    Object.keys(formState).forEach((formElementName) => {
        const { componentName: filterType, filterMetadataKey: filterName } = parseFormElementName(formElementName);

        if (!Object.keys(filtersData.filter_metadata).includes(filterName)) {
            return;
        }

        const requestMappingValue =
            filtersData.filter_metadata[filterName as keyof typeof filtersData.filter_metadata].request_mapping;

        if (filterTypeToArrayFiltersStateData[filterType as Components] === undefined) {
            filterTypeToArrayFiltersStateData[filterType as Components] = [];
        }

        if (!(Object.values(Components) as Array<string>).includes(filterType)) {
            throw new Error(`Unknown filter type ${filterType}`);
        }
        let filtersStateData = [];
        if (typeof requestMappingValue === 'object') {
            filtersStateData = Object.keys(requestMappingValue).map((filterKey) => ({
                [filterKey]: { filterStateData: formState[formElementName], filtersMetadataKey: filterName },
            }));
        } else {
            filtersStateData = [
                {
                    [requestMappingValue]: {
                        filterStateData: formState[formElementName],
                        filtersMetadataKey: filterName,
                    },
                },
            ];
        }
        filterTypeToArrayFiltersStateData[filterType as Components]?.push(...filtersStateData);
    });

    return filterTypeToArrayFiltersStateData;
}

export function getCombinedSliderFilterItemMetadata(filterMetadata: FilterMetadata): MetadataFieldDefinition {
    const getFormat = () => {
        switch (filterMetadata.item_metadata.symbol) {
            case '$':
                return filterMetadata.item_metadata.format || '#0';
            case '%':
                return '0.00';
            default:
                return filterMetadata.item_metadata.format;
        }
    };

    return { ...filterMetadata.item_metadata, format: getFormat() };
}

export function getDynamicScale(rawValue: number) {
    const { h, b, ...rest } = scaleCharacterToNumber; // exclude hundreds and billions
    const valueToLetterRepresentation = invert({ ...rest });
    const dividers = Object.keys(valueToLetterRepresentation)
        .map((valueItem) => Number(valueItem))
        .filter((valueItem) => rawValue / valueItem > 1 || rawValue / valueItem < -1)
        .sort((n1, n2) => n2 - n1);

    if (dividers.length > 0) {
        return valueToLetterRepresentation[dividers[0]].toUpperCase() as Scale;
    }

    return undefined;
}

const throwMultipleReferenceError = (component: Components, key: string) => {
    throw new Error(
        `Detected ${component} component with reference to the same db field with another component - ${key}`,
    );
};

export type DirtyFields = { [key: string]: boolean };
export type RhFormData = { [key: string]: any };

export function getDirtyData(dirtyFields: DirtyFields, formData: RhFormData): RhFormData {
    /**
     * Transforms the dirty fields data by filtering out the fields that are not dirty.
     *
     * @param dirtyFields - The dirty fields object containing the fields that have been modified.
     * @param formData - The original form data object.
     * @returns The transformed dirty data object.
     *
     * @example
     * const dirtyFields = {
     *   name: true,
     *   age: false,
     *   email: true
     * };
     *
     * const formData = {
     *   name: 'John',
     *   age: 25,
     *   email: 'john@example.com'
     * };
     *
     * const dirtyData = getDirtyData(dirtyFields, formData);
     *
     * // dirtyData will be:
     * // {
     * //   name: 'John',
     * //   email: 'john@example.com'
     * // }
     */
    return Object.keys(dirtyFields).reduce((result, current) => {
        return dirtyFields[current] ? { ...result, [current]: formData[current] } : result;
    }, {});
}

export function getPostAndChipsData(
    formState: {
        [x: string]: any;
    },
    filtersData: FiltersData,
): { postData?: PostData; chipItems: ChipsData } {
    const filterTypeToArrayFiltersStateData = getFilterTypeToArrayFiltersStateData(formState, filtersData);

    let labels = Object.values(filtersData.filter_metadata).map((metaData) => metaData.label);
    const chipItems: ChipsData = {};
    const postData: PostData = {};

    Object.keys(filterTypeToArrayFiltersStateData).forEach((filterType) => {
        const filtersStateData = filterTypeToArrayFiltersStateData[filterType as Components];

        switch (filterType) {
            case Components.Slider:
                filtersStateData?.forEach((filterStateData) => {
                    Object.keys(filterStateData).forEach((key) => {
                        if ((filterStateData[key].filterStateData as Array<number>).length === 0) {
                            return;
                        }

                        if (Object.keys(postData).includes(key)) {
                            throwMultipleReferenceError(Components.Slider, key);
                        }

                        const data = filterStateData[key];
                        const filterData = data.filterStateData as Array<number | string>;
                        const defaultRange = filtersData.filter_metadata[key].range;
                        const realRange = filtersData.data[key];

                        if (
                            !Array.isArray(filterData) ||
                            filterData.length < 2 ||
                            !Array.isArray(defaultRange) ||
                            defaultRange.length < 2
                        ) {
                            throw new Error(
                                `Invalid range or selected values for slider with db field reference to ${key}`,
                            );
                        }

                        const isDollarDataPoint = filtersData.filter_metadata[key].item_metadata.symbol === '$';
                        const isPercentageDataPoint = filtersData.filter_metadata[key].item_metadata.symbol === '%';

                        const filterDataMin = Number(filterData[0]);
                        const filterDataMax = Number(filterData[1]);

                        const sliderApplyScaleToValueLocal = (value: number) =>
                            sliderApplyScaleToValue({
                                value: value,
                                isPercentageDataPoint: isPercentageDataPoint,
                                itemMetadata: filtersData.filter_metadata[key].item_metadata,
                            });

                        const sliderUnApplyScaleFromValueLocal = (value: string) =>
                            sliderUnApplyScaleFromNumericValue(filtersData.filter_metadata[key].item_metadata, value);

                        let defaultMin = typeof realRange?.min === 'number' ? realRange?.min : defaultRange[0];
                        let defaultMax = typeof realRange?.max === 'number' ? realRange?.max : defaultRange[1];

                        // expect slider values with already applied scale for not dollar data points, so need to apply scale to default values to compare them
                        if (!isDollarDataPoint) {
                            defaultMin = sliderApplyScaleToValueLocal(defaultMin);
                            defaultMax = sliderApplyScaleToValueLocal(defaultMax);
                        }

                        let min = filterDataMin === defaultMin ? null : filterDataMin;
                        let max = filterDataMax === defaultMax ? null : filterDataMax;

                        // expect slider values with already applied scale for not dollar data points, so need to apply scale to default values to compare them
                        if (!isDollarDataPoint) {
                            min = min === null ? null : sliderUnApplyScaleFromValueLocal(String(min));
                            max = max === null ? null : sliderUnApplyScaleFromValueLocal(String(max));
                        }

                        if (!min && typeof min !== 'number' && !max && typeof max !== 'number') {
                            return;
                        }

                        // fill slider post data
                        postData[key] = {
                            values: [min, max],
                        };

                        // fill slider chips data
                        chipItems[data.filtersMetadataKey] = getChipItem(
                            filtersData.filter_metadata[data.filtersMetadataKey].label,
                            (function (item_metadata: MetadataFieldDefinition, filterData: Array<number | string>) {
                                const formatValue = (value: number | string) => {
                                    return applyFieldMetadataToValue({
                                        fieldMetadata: {
                                            ...getCombinedSliderFilterItemMetadata(
                                                filtersData.filter_metadata[data.filtersMetadataKey],
                                            ),
                                            scale:
                                                item_metadata.symbol === '$'
                                                    ? getDynamicScale(Number(value))
                                                    : undefined,
                                            // eslint-disable-next-line no-template-curly-in-string
                                            value_template: `{value}${
                                                item_metadata.symbol === '%' ? '' : ' '
                                            }{scale}{symbol}`, // percentage should be quickly after number, without space
                                        },
                                        rawValue: value,
                                    });
                                };

                                return `${formatValue(filterData[0])} to ${formatValue(filterData[1])}`;
                            })(filtersData.filter_metadata[data.filtersMetadataKey].item_metadata, filterData),
                            data.filterStateData as (number | null)[] | undefined,
                            combineIntoFormElementName({
                                componentName: filterType,
                                filterMetadataKey: data.filtersMetadataKey,
                            }),
                            getFilterSectionMapping(data.filtersMetadataKey, filtersData),
                        );

                        if (
                            labels.filter((label) => label === chipItems[data.filtersMetadataKey].chip.label).length > 1
                        ) {
                            chipItems[data.filtersMetadataKey].chip.additionalLabels = addChipAdditionalLabels(
                                filtersData,
                                key,
                            );
                        }
                    });
                });
                break;
            case Components.DateRangePicker:
                filtersStateData?.forEach((filterStateData) => {
                    Object.keys(filterStateData).forEach((key) => {
                        const values = (filterStateData[key].filterStateData as Array<Dayjs | null | undefined>).map(
                            formatDayjsValue,
                        );
                        // Ignore applied DateRangePicker filter if the date range is invalid
                        if (isInValidDateRange(values[0], values[1])) {
                            return;
                        }

                        if (values.filter((value) => value !== null).length > 0) {
                            // fill date range picker post data
                            postData[key] = {
                                values: values,
                            };

                            // fill date range picker chips data
                            let metaDataKey = filterStateData[key].filtersMetadataKey;

                            chipItems[metaDataKey] = getChipItem(
                                filtersData.filter_metadata[metaDataKey].label,
                                values.filter((value) => value !== null).join(' to '),
                                values as Array<string>,
                                combineIntoFormElementName({
                                    componentName: filterType,
                                    filterMetadataKey: metaDataKey,
                                }),
                                getFilterSectionMapping(metaDataKey, filtersData),
                            );
                        }
                    });
                });
                break;
            case Components.Checkbox:
            case Components.Switch:
                filtersStateData?.forEach((filterStateData) => {
                    Object.keys(filterStateData).forEach((key) => {
                        if (filterStateData[key].filterStateData === true) {
                            if (!Object.keys(postData).includes(key)) {
                                postData[key] = {};
                            }
                            const requestMappingValue =
                                filtersData.filter_metadata[filterStateData[key].filtersMetadataKey].request_mapping;
                            if (typeof requestMappingValue === 'object') {
                                Object.keys(requestMappingValue).forEach((filterKey) => {
                                    if (!postData[filterKey]) {
                                        postData[filterKey] = {};
                                    }

                                    const { values, excluded_values } = requestMappingValue[filterKey]['request_value'];

                                    if (values) {
                                        postData[filterKey]['values'] = uniq([
                                            ...(postData[filterKey]['values'] || []),
                                            ...values,
                                        ]);
                                    }
                                    if (excluded_values) {
                                        postData[filterKey]['excluded_values'] = uniq([
                                            ...(postData[filterKey]['excluded_values'] || []),
                                            ...excluded_values,
                                        ]);
                                    }
                                });
                            }

                            const requestValue =
                                filtersData.filter_metadata[filterStateData[key].filtersMetadataKey].request_value;

                            // fill checkbox and switch post data values
                            if (requestValue && Object.keys(requestValue).includes('value') && requestValue.value) {
                                postData[key] = { values: [], ...postData[key] };
                                if (!postData[key].values?.includes(requestValue.value)) {
                                    postData[key].values?.push(requestValue.value);
                                }
                            }

                            // fill checkbox and switch post data excluded values
                            if (
                                requestValue &&
                                Object.keys(requestValue).includes('excluded_value') &&
                                requestValue.excluded_value
                            ) {
                                postData[key] = { excluded_values: [], ...postData[key] };
                                if (!postData[key].excluded_values?.includes(requestValue.excluded_value)) {
                                    postData[key].excluded_values?.push(requestValue.excluded_value);
                                }
                            }

                            // fill checkbox and switch chips data
                            let metaDataKey = filterStateData[key].filtersMetadataKey;

                            chipItems[metaDataKey] = getChipItem(
                                filtersData.filter_metadata[metaDataKey].label,
                                '',
                                filterStateData[key].filterStateData as boolean | undefined,
                                combineIntoFormElementName({
                                    componentName: filterType,
                                    filterMetadataKey: metaDataKey,
                                }),
                                getFilterSectionMapping(metaDataKey, filtersData),
                            );
                        }
                    });
                });
                break;
            case Components.Autocomplete:
            case Components.AutocompleteSearchTable:
            case Components.Rating:
                filtersStateData?.forEach((filterStateData) => {
                    Object.keys(filterStateData).forEach((key) => {
                        const data = filterStateData[key];
                        if ((data.filterStateData as Array<Item>).length === 0 || !data.filterStateData) {
                            return;
                        }

                        if (!Object.keys(postData).includes(key)) {
                            postData[key] = {};
                        }

                        postData[key] = { values: [], ...postData[key] };

                        // fill autocomplete post data values
                        postData[key].values = postData[key].values?.concat(
                            (data.filterStateData as Array<Item>).map((item) => item.key),
                        );

                        // fill autocomplete chips data
                        (data.filterStateData as Array<Item>).reduce((chips, state) => {
                            chipItems[data.filtersMetadataKey + state.key] = getChipItem(
                                filtersData.filter_metadata[data.filtersMetadataKey].label,
                                state.value,
                                [state],
                                combineIntoFormElementName({
                                    componentName: filterType,
                                    filterMetadataKey: data.filtersMetadataKey,
                                }),
                                getFilterSectionMapping(data.filtersMetadataKey, filtersData),
                            );

                            return chips;
                        }, chipItems);
                    });
                });
                break;
        }
    });

    return {
        postData: Object.keys(postData).length > 0 ? postData : undefined,
        chipItems: chipItems,
    };
}

export const getViewObj = (key: string, viewdataFields: Array<IViewDataFields>) => {
    return viewdataFields.filter((field) => {
        const viewObjKeys = Object.keys(field);

        if (viewObjKeys.length === 0) {
            return false;
        }

        return viewObjKeys[0] === key;
    });
};

export function sortMetadataFieldsByViewOrder(
    metadataFields: Array<IMetadataFields>,
    viewdataFields: Array<IViewDataFields>,
): Array<IMetadataFields> {
    return metadataFields.sort((value1, value2) => {
        const metadataObjKeys1 = Object.keys(value1);
        const metadataObjKeys2 = Object.keys(value2);

        if (metadataObjKeys1.length === 0 || metadataObjKeys2.length === 0) {
            throw new Error('metadataObjKeys1 or metadataObjKeys2 is empty.');
        }

        let key1 = metadataObjKeys1[0];
        let key2 = metadataObjKeys2[0];
        const viewObj1 = getViewObj(key1, viewdataFields);
        const viewObj2 = getViewObj(key2, viewdataFields);

        if (viewObj1.length === 0 || viewObj2.length === 0) {
            throw new Error("Can't find order value for viewObj1 or viewObj2");
        }

        const viewObj1First = viewObj1[0];
        const viewObj2First = viewObj2[0];

        return viewObj1First[Object.keys(viewObj1First)[0]].order - viewObj2First[Object.keys(viewObj2First)[0]].order;
    });
}

export function extractOrExcludeDefaultSelectedColumns(
    exclude: boolean, // if true exclude default selected, otherwise extract default selected
    metadataFields: Array<IMetadataFields>,
    viewdataFields: Array<IViewDataFields>,
): Array<IMetadataFields> {
    return metadataFields.filter((value1) => {
        const metadataObjKeys = Object.keys(value1);

        if (metadataObjKeys.length === 0) {
            throw new Error('metadataObjKeys is empty.');
        }

        const viewObj = getViewObj(metadataObjKeys[0], viewdataFields);

        if (viewObj.length === 0) {
            throw new Error("Can't find order value for viewObj");
        }

        const viewObj1First = viewObj[0];
        const defaultSelected = viewObj1First[Object.keys(viewObj1First)[0]].default_selected;

        return exclude ? !defaultSelected : defaultSelected;
    });
}

export function sortByOrder(items: Array<StringKeyValueItemWithData>) {
    return items.sort((obj1, obj2) => obj1.viewData.order - obj2.viewData.order);
}

export function handleDefaultSelected(mode: 'exclude' | 'extract', items: Array<StringKeyValueItemWithData>) {
    return items.filter((item) =>
        mode === 'exclude' ? !item.viewData.default_selected : item.viewData.default_selected,
    );
}

export function getViewDataForColumn(key: string, viewdataFields: Array<IViewDataFields>) {
    const viewObj = getViewObj(key, viewdataFields);

    if (viewObj.length === 0) {
        throw new Error("Can't find order value for viewObj");
    }

    const viewObj1First = viewObj[0];
    const viewObj1FirstData = viewObj1First[Object.keys(viewObj1First)[0]];

    return viewObj1FirstData;
}

export function formatSavedFilterToDirtyData(savedFilters: { [x: string]: any }, filtersData: FiltersData) {
    type ExtendedComponents = Components | 'dropdown';
    let dirtyData: any = {};
    let mismatchedSavedData: any = {}; // object holds value to be set instead of dirtyData, in case it mismatch.

    Object.keys(savedFilters).forEach((filterKey) => {
        const filterMetadata = filtersData.filter_metadata[filterKey];
        if (filterMetadata) {
            let filterType = filterMetadata.component as ExtendedComponents;
            if (filterType === 'dropdown') {
                filterType = Components.Autocomplete;
            }

            const dirtyFieldKey = combineIntoFormElementName({
                componentName: filterType,
                filterMetadataKey: filterKey,
            });

            if (
                [Components.Autocomplete, Components.AutocompletePicklist, Components.AutocompleteSearchTable].includes(
                    filterType as Components,
                )
            ) {
                const dropdownOptions = filtersData.data[filterKey]?.items;

                if (Array.isArray(dropdownOptions) && dropdownOptions.length > 0) {
                    dirtyData[dirtyFieldKey] = savedFilters[filterKey].reduce(
                        (selectedOptions: any, curr: { [x: string]: any }) => {
                            const matchedItem = dropdownOptions.find((item) => item.key === curr['key']);
                            if (matchedItem) {
                                selectedOptions.push(matchedItem);
                            }
                            return selectedOptions;
                        },
                        [],
                    );
                }
            } else if ([Components.DateRangePicker].includes(filterType as Components)) {
                dirtyData[dirtyFieldKey] = (savedFilters[filterKey] as Array<Dayjs | null | undefined>).map((value) =>
                    value ? dayjs(value) : null,
                );
            } else if ([Components.Checkbox, Components.Switch, Components.Slider].includes(filterType as Components)) {
                if (filterType === Components.Slider) {
                    const isPercentageDataPoint = filtersData.filter_metadata[filterKey].item_metadata.symbol === '%';
                    const isDollarDataPoint = filterMetadata.item_metadata.symbol === '$';

                    let adjustedMin = filtersData.data[filterKey]?.min,
                        adjustedMax = filtersData.data[filterKey]?.max;

                    if (adjustedMin !== undefined) {
                        if (!isDollarDataPoint) {
                            adjustedMin = sliderApplyScaleToValue({
                                value: adjustedMin,
                                isPercentageDataPoint: isPercentageDataPoint,
                                itemMetadata: filtersData.filter_metadata[filterKey].item_metadata,
                            });
                        }
                    }
                    if (adjustedMax !== undefined) {
                        if (!isDollarDataPoint) {
                            adjustedMax = sliderApplyScaleToValue({
                                value: adjustedMax,
                                isPercentageDataPoint: isPercentageDataPoint,
                                itemMetadata: filtersData.filter_metadata[filterKey].item_metadata,
                            });
                        }
                    }

                    mismatchedSavedData[dirtyFieldKey] = [
                        adjustedMin !== undefined && savedFilters[filterKey][0] < adjustedMin
                            ? adjustedMin
                            : savedFilters[filterKey][0],
                        adjustedMax !== undefined && savedFilters[filterKey][1] > adjustedMax
                            ? adjustedMax
                            : savedFilters[filterKey][1],
                    ];
                }
                dirtyData[dirtyFieldKey] = savedFilters[filterKey];
            } else if (filterType === Components.Rating) {
                dirtyData[dirtyFieldKey] = savedFilters[filterKey].map((p: any) => ({
                    key: p.key,
                    value: p.key,
                }));
            }
        }
    });
    return { dirtyData, mismatchedSavedData };
}

export function formatQuerystringDataToDirtyData(filterKey: string, filterValue: string, filtersData: FiltersData) {
    type ExtendedComponents = Components | 'dropdown';
    let dirtyData: any = {};

    const filterMetadata = filtersData.filter_metadata[filterKey];
    if (filterMetadata) {
        let filterType = filterMetadata.component as ExtendedComponents;
        if (filterType === 'dropdown') {
            filterType = Components.Autocomplete;
        }

        const dirtyFieldKey = combineIntoFormElementName({
            componentName: filterType,
            filterMetadataKey: filterKey,
        });

        if (
            [
                Components.Autocomplete,
                Components.AutocompletePicklist,
                Components.AutocompleteSearchTable,
                Components.Rating,
            ].includes(filterType as Components)
        ) {
            const dropdownOptions = filtersData.data[filterKey]?.items;
            dirtyData[dirtyFieldKey] = filterValue.split(',').reduce((selectedOptions: any, currVal: string) => {
                if (filterType === Components.Rating) {
                    selectedOptions.push({
                        key: currVal,
                        value: currVal,
                    });
                } else {
                    const matchedItem = dropdownOptions.find(
                        (item) => item.key.toString().toLowerCase() === currVal.toLowerCase(),
                    );
                    if (matchedItem) {
                        selectedOptions.push(matchedItem);
                    }
                }
                return selectedOptions;
            }, []);
        }
    }
    return dirtyData;
}

export function getSaveFiltersFromDirtyData(dirtyFields: RhFormData, filtersData: FiltersData) {
    let saveFilters: RhFormData = {};

    Object.keys(dirtyFields).forEach((dirtyFilterKey) => {
        if (dirtyFields[dirtyFilterKey as keyof typeof dirtyFields]) {
            const { componentName: filterType, filterMetadataKey: filterKey } = parseFormElementName(dirtyFilterKey);

            if (!Object.keys(filtersData.filter_metadata).includes(filterKey)) {
                throw new Error(`Unknown filter type ${filterKey}`);
            }

            if (!(Object.values(Components) as Array<string>).includes(filterType)) {
                throw new Error(`Unknown filter type ${filterType}`);
            }

            if (
                [
                    Components.Autocomplete,
                    Components.AutocompletePicklist,
                    Components.AutocompleteSearchTable,
                    Components.Rating,
                ].includes(filterType as Components)
            ) {
                const values = dirtyFields[dirtyFilterKey].map((option: any) => ({ key: option['key'] }));

                if (values.length > 0) {
                    saveFilters[filterKey] = values;
                }
            } else if ([Components.DateRangePicker].includes(filterType as Components)) {
                const values = (dirtyFields[dirtyFilterKey] as Array<Dayjs | null | undefined>).map(formatDayjsValue);

                // Ignore applied DateRangePicker filter if the date range is invalid
                if (isInValidDateRange(values[0], values[1])) {
                    return;
                }

                saveFilters[filterKey] = values;
            } else if ([Components.Checkbox, Components.Switch, Components.Slider].includes(filterType as Components)) {
                saveFilters[filterKey] = dirtyFields[dirtyFilterKey];
            }
        }
    });
    return saveFilters;
}

export function compareFilters(filter1: { [x: string]: any }, filter2: { [x: string]: any }) {
    try {
        const sortFilterArrayValues = (inputObj: any) => {
            let obj = inputObj;
            for (const key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    sortFilterArrayValues(obj[key]);

                    if (Array.isArray(obj[key])) {
                        obj[key] = obj[key].sort();
                    } else if (typeof obj[key] === 'object') {
                        for (const nestedKey in obj[key]) {
                            if (Array.isArray(obj[key][nestedKey])) {
                                obj[key][nestedKey] = obj[key][nestedKey].sort();
                            }
                        }
                    }
                }
            }
            return obj;
        };

        const sortedFilter1 = sortFilterArrayValues(filter1);
        const sortedFilter2 = sortFilterArrayValues(filter2);
        return isEqual(sortedFilter1, sortedFilter2);
    } catch (error) {
        return false;
    }
}

function getFilterSectionMapping(filterKey: string, filtersData: FiltersData) {
    if (filterKey && filtersData?.filter_metadata?.[filterKey]?.['sections']) {
        const filterSectionDetails = filtersData.filter_metadata[filterKey]['sections'];
        const filterSection = Object.keys(filterSectionDetails)[0];

        //group section by level and key for finding filter order index
        const groupedSections = getSectionGroupByLevelParent(filtersData.section_mapping);

        return getSectionMapping(filterSection, filtersData.section_mapping, groupedSections, {});
    }
    return {};
}

function getSectionMapping(
    section: string,
    sectionMapping: { [x: string]: any },
    groupedSections: { [x: string]: any },
    mapping = {},
): any {
    const { level, order, parent } = sectionMapping[section];

    // find index of the sections
    const orderIndex = groupedSections[`${level}_${parent}`].findIndex(
        (item: { [x: string]: number }) => item['order'] === order,
    );

    let tempMapping: any = {
        ...mapping,
        [section]: { ...sectionMapping[section], orderIndex },
    };

    const parentSection = sectionMapping[section]['parent'];
    if (parentSection) {
        return getSectionMapping(parentSection, sectionMapping, groupedSections, tempMapping);
    }
    return tempMapping;
}

function getSectionGroupByLevelParent(sectionMapping: { [x: string]: any }) {
    //group sections by level and parent
    const grouped = Object.values(sectionMapping).reduce((result, obj) => {
        const key = `${obj['level']}_${obj['parent']}`;
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(obj);
        return result;
    }, {} as { [key: string]: Array<any> });

    // Sort each group based order
    Object.keys(grouped).forEach((key) => {
        grouped[key].sort((a: any, b: any) => a['order'] - b['order']);
    });

    return grouped;
}

export function getEtfFilterByIdQuery(ids: string[]) {
    return {
        'etf_data.data_etf_universe.firstbridge_id': {
            values: ids,
        },
    };
}

function formatDayjsValue(value: string | number | dayjs.Dayjs | Date | null | undefined) {
    if (value) {
        const dateObj = dayjs.isDayjs(value) ? value : dayjs(value);
        return dateObj.isValid() ? dateObj.format(standardDateFormat) : null;
    }
    return null;
}

function isInValidDateRange(
    startDate: string | number | Date | null = null,
    endDate: string | number | Date | null = null,
) {
    if (startDate !== null && endDate !== null && new Date(startDate) > new Date(endDate)) {
        return true;
    }
    return false;
}

export const generateETFScreenerLink = (filters: { filterName: string; filterValue: string }[]): string | undefined => {
    if (filters.length === 0) return undefined;

    const queryString = filters
        .map((filter) => {
            return `${filter.filterName}|${filter.filterValue}`;
        })
        .join('||');

    return `/screener?filters=${encodeURIComponent(queryString)}`;
};

type EntityRequestBodyTypes = {
    [key: string]: any;
};

function getFormValueByRequestValue(formValue: Array<any> | any, requestValue: Record<string, string>, userId: string) {
    if (!Array.isArray(formValue) && typeof formValue !== 'object') {
        throw new Error(`getFormValueByRequestValuePath exception. Invalid form value - ${formValue}`);
    }

    if (Array.isArray(formValue)) {
        return formValue.map((item) => mapValues(requestValue, { ...item, user_id: userId }));
    }

    return mapValues(requestValue, { ...formValue, user_id: userId });
}

export function getOperateEntityRequestBody({
    formState,
    filtersData,
    userId,
    sectionKey,
    requestDefaultValue,
}: {
    formState: {
        [x: string]: any;
    };
    filtersData: FiltersData;
    userId: string;
    sectionKey?: string;
    requestDefaultValue?: Record<string, any>;
}): EntityRequestBodyTypes | Array<EntityRequestBodyTypes> {
    let requestBody: ReturnType<typeof getOperateEntityRequestBody> = {};
    const invalidRequestBody =
        'getOperateEntityRequestBody exception. The requestBody cant be object and array simultaneously.';

    Object.entries(formState).forEach(([formElementName, formValue]) => {
        if (Array.isArray(formValue) && formValue.length === 0) {
            return;
        }

        const { filterMetadataKey, uniqueIdWithinGroup } = parseFormElementName(formElementName);

        const filterMetadata = filtersData.filter_metadata[filterMetadataKey];

        if (filterMetadata.component === Components.FilePicker) {
            return;
        }

        if (filterMetadata.response_value) {
            formValue = getFormValueByRequestValue(formValue, filterMetadata.response_value, userId);
        }

        if (filterMetadata.response_mapping) {
            requestBody = merge(requestBody, addPathToValue(filterMetadata.response_mapping, formValue));
            return;
        }

        if (sectionKey && uniqueIdWithinGroup) {
            if (!Array.isArray(requestBody) && Object.keys(requestBody).length > 0) {
                throw new Error(invalidRequestBody);
            }

            if (!Array.isArray(requestBody)) {
                requestBody = [];
            }

            const actions = filtersData.filter_metadata?.[filterMetadataKey]?.sections?.[sectionKey]?.actions;

            if (!actions) {
                throw new Error('actions is required for row level filters');
            }

            const actionMatchedToValue = actions.find((action) => action.filter_value === formValue);

            if (!actionMatchedToValue) {
                throw new Error(
                    `cant find action value matched to the filter value for filter with key ${filterMetadataKey}`,
                );
            }

            let requestBodyItem: Record<string, any> = {};

            if (requestDefaultValue) {
                requestBodyItem = replaceAllPlaceholdersInObject(
                    requestDefaultValue,
                    {
                        row_id: Number(uniqueIdWithinGroup),
                        action_value: actionMatchedToValue.request_values,
                    },
                    true,
                );
            }

            requestBody.push(
                replaceAllPlaceholdersInObject(
                    Object.keys(requestBodyItem).length > 0 ? requestBodyItem : actionMatchedToValue.request_values,
                    {
                        now: getDateTimeUtcNow(),
                    },
                    true,
                ),
            );

            return;
        }

        if (Array.isArray(requestBody)) {
            throw new Error(invalidRequestBody);
        }

        const entityFieldName = filterMetadata.item_metadata.response_mapping?.default;

        if (typeof entityFieldName !== 'string') {
            throw new Error(`Invalid entity field name`);
        }

        requestBody[entityFieldName] = formValue;
    });

    // handle hidden filters
    Object.values(filtersData.filter_metadata).forEach((filterMetadataValue) => {
        if (filterMetadataValue.component !== Components.Hidden) {
            return;
        }

        requestBody = merge(
            requestBody,
            replaceAllPlaceholdersInObject(filterMetadataValue.request_default_value, {
                ...requestBody,
                user_id: userId,
            }),
        );
    });

    // handle file picker
    Object.entries(formState).forEach(([formElementName, formValue]) => {
        const { filterMetadataKey } = parseFormElementName(formElementName);
        const filterMetadata = filtersData.filter_metadata[filterMetadataKey];

        if (filterMetadata.component !== Components.FilePicker) {
            return;
        }

        const fileColumnNameToRequestKey = filterMetadata.file_column_name_to_request_key;

        if (!fileColumnNameToRequestKey) {
            throw new Error(
                `getOperateEntityRequestBody exception. The file picker file_column_name_to_request_key is not provided.`,
            );
        }

        const filePickerValue = formValue as FilePickerFormState;

        if (!filePickerValue.data) {
            throw new Error(`getOperateEntityRequestBody exception. The file picker data is empty.`);
        }

        const newRequestBody: Array<EntityRequestBodyTypes> = [];

        const requestKeys = Object.values(filePickerValue.data[0]).map((columnNameFromFile) => {
            if (!Object.keys(fileColumnNameToRequestKey).includes(columnNameFromFile)) {
                throw new Error(
                    `getOperateEntityRequestBody exception. The file contains column name out of fileColumnNameToRequestKey - ${columnNameFromFile}`,
                );
            }

            return fileColumnNameToRequestKey[columnNameFromFile as keyof typeof fileColumnNameToRequestKey];
        });

        filePickerValue.data.slice(1).forEach((row) => {
            newRequestBody.push({
                ...requestBody,
                ...requestKeys.reduce((acc, key, index) => {
                    acc[key] = Object.values(row)[index];
                    return acc;
                }, {} as Record<string, string>),
            });
        });

        requestBody = newRequestBody;
    });

    return requestBody;
}

export function getFormValueByFilterKey(filterKey: string, formData: RhFormData, filtersData: FiltersData) {
    let metaData = filtersData?.filter_metadata;
    if (metaData && formData) {
        let formKey = combineIntoFormElementName({
            componentName: metaData[filterKey].component,
            filterMetadataKey: filterKey,
        });

        return formData?.[formKey];
    }
}
