import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { getPostAndChipsData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { ScreenerFormDateRangePicker } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormDateRangePicker';
import { ScreenerFormVirtualizeAutocomplete } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormVirtualizeAutocomplete';
import { Grid, Skeleton } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box } from '@mui/material';
import { getFiltersData } from 'components/screener/api/screener';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

export function FiltersForm() {
    const [filtersPostData, setFiltersPostData] = useState<Object | undefined>(undefined);
    const filtersDataUseQueryResult = getFiltersData({
        requestBody: getFiltersReqBody(filtersPostData),
        securityType: 'research',
    });
    const [filtersData, setFiltersData] = useState<FiltersData | undefined>(undefined);
    const [submittingData, setSubmittingData] = useState<{ [key: string]: any } | null>(null);

    // keep the comment here to avoid the hard to detect issue in the feature: if import isValid property from formState, it causes 900+ unnecessary validation triggers, it influence on performance
    const {
        control,
        formState: { dirtyFields, errors, isValidating },
        getValues,
        setValue,
        handleSubmit,
        trigger,
    } = useForm({
        reValidateMode: 'onSubmit',
    });

    const validate: (fieldName: string) => Promise<boolean | undefined> = useCallback(
        async (fieldName: string) => {
            return await trigger?.(fieldName);
        },
        [trigger],
    );

    useEffect(() => {
        const onSubmit = (data: any) => {
            const dirtyData = Object.keys(dirtyFields).reduce((result, current) => {
                return dirtyFields[current as keyof typeof dirtyFields]
                    ? { ...result, [current]: data[current as keyof typeof data] }
                    : result;
            }, {});

            if (!filtersData) {
                return;
            }
            const { postData } = getPostAndChipsData(dirtyData, filtersData);

            // const postDataOrUndefined = Object.keys(postData).length > 0 ? postData : undefined;

            setFiltersPostData(postData);
        };

        if (!isValidating && Object.keys(errors).length === 0 && submittingData) {
            onSubmit(submittingData);
            setSubmittingData(null);
        }
    }, [isValidating, errors, submittingData, dirtyFields, filtersData, setFiltersPostData]);

    const submitHandler: () => void = useCallback(
        () => handleSubmit((data) => setSubmittingData(data))(),
        [handleSubmit],
    );

    const reactNodes: React.ReactNode = useMemo(() => {
        if (!filtersData) {
            return null;
        }

        return (
            <Grid container xs={12}>
                <Grid item container xs={1.8}>
                    <ScreenerFormVirtualizeAutocomplete
                        defaultInputLabel={'Document type'}
                        useFormLabelWithContainer={false}
                        control={control}
                        filtersData={filtersData}
                        filterMetadataKey={'document.type'}
                        submitHandler={submitHandler}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                    />
                </Grid>
                <Grid item container xs={1.8}>
                    <ScreenerFormVirtualizeAutocomplete
                        defaultInputLabel={'Author'}
                        useFormLabelWithContainer={false}
                        control={control}
                        filtersData={filtersData}
                        filterMetadataKey={'author'}
                        submitHandler={submitHandler}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                    />
                </Grid>
                <Grid item container xs={3}>
                    <ScreenerFormDateRangePicker
                        useFormLabelWithContainer={false}
                        control={control}
                        filtersData={filtersData}
                        filterMetadataKey={'daterange'}
                        submitHandler={submitHandler}
                        getValues={getValues}
                        parentSectionKey={'root'}
                        setValue={setValue}
                        validate={validate}
                        hide={false}
                    />
                </Grid>
            </Grid>
        );
    }, [control, filtersData, getValues, validate, submitHandler, setValue]);

    if (!filtersData && filtersDataUseQueryResult.isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <Skeleton />
            </Box>
        );
    }

    if (
        !filtersDataUseQueryResult.isLoading &&
        !(filtersDataUseQueryResult.data && filtersDataUseQueryResult.data.filter_metadata)
    ) {
        return <NoInformationAvailable emptyCardText='No filters information available.' />;
    }

    if (!filtersData) {
        setFiltersData(filtersDataUseQueryResult.data);
        return <></>;
    }

    return (
        <Grid sx={{ width: '100%', height: '100%' }}>
            <form>{reactNodes}</form>
        </Grid>
    );
}
