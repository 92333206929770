import { Typography } from '@cfra-nextgen-frontend/shared';
import { Box, Stack } from '@mui/material';
import HeaderImage from 'assets/images/ETFMainBannerHeader.svg';
import Grid from 'components/layout/Grid';

export function HeroSection() {
    return (
        <Box component='div'>
            <Grid container columnSpacing={2} rowSpacing={8}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack spacing={4}>
                        <Typography variant='h1' textAlign={'left'}>
                            A dynamic data platform designed by ETF pros for ETF pros
                        </Typography>
                        <Typography variant='subtitle1' textAlign={'left'}>
                            Quickly gain real-time insight and analyze trends for the global ETF market and individual
                            funds
                        </Typography>
                        <Box component='div'>
                            {/* <Link
                                component={RouterLink}
                                to='/market-trends'
                                underline='none'>
                                <RoundedButton>{getStartedButtonText}</RoundedButton>
                            </Link> */}
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} display='flex'>
                    <Box component='img' src={HeaderImage} width='100%' height='auto' />
                </Grid>
            </Grid>
        </Box>
    );
}
