import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { getFullRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { rest } from 'msw';
import screenerFiltersData from './json/screenerFiltersData.json';

const getFullPlatformManagementInternalRequestQuery = (requestQuery: string) =>
    getFullRequestQuery({ requestQuery: requestQuery, apiName: ApiNames.Research });

export const handlersForTests = [
    // Create Handler for topics filters
    rest.get(`${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('screener-filters')}`, (req, res, ctx) => {
        return res(ctx.json(screenerFiltersData));
    }),

    // Create Handler for topics filters
    rest.post(
        `${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('screener-filters')}`,
        (req, res, ctx) => {
            return res(ctx.json(screenerFiltersData));
        },
    ),
];

export const handlersForMSW = handlersForTests;
