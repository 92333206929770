import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { useState } from 'react';
import { getResearchTopics } from '../api/research';
import { Topics } from './Topics';

export function ResearchHome() {
    const topicsData = getResearchTopics();
    const [selectedTopics, setSelectedTopics] = useState<Array<number>>([]);
    return (
        <Grid container>
            <Grid item sm={12}>
                <Topics allTopics={topicsData} topics={{ selectedTopics, setSelectedTopics }} />
            </Grid>
        </Grid>
    );
}
