import { Box, styled } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ETFPageContainer } from 'components/layout/ETFPageContainer';
import { homePageTheme } from 'components/themes/theme';
import { HeroSection } from '../components/HeroSection/HeroSection';

export function Home() {
    const HeroSectionContainer = styled(Box)<{ component: string }>(({ theme }) => ({
        padding: '90px 24px',
        backgroundColor: '#F2F5FC',
        maxWidth: '1366px',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            padding: '90px 60px',
        },
    }));

    return (
        <ThemeProvider theme={homePageTheme}>
            <ETFPageContainer containerStyles={{ maxWidth: 'unset', backgroundColor: 'unset' }}>
                <HeroSectionContainer component='section'>
                    <HeroSection />
                </HeroSectionContainer>
            </ETFPageContainer>
        </ThemeProvider>
    );
}
