import { useAuthenticator } from '@aws-amplify/ui-react';
import { Fallback } from '@cfra-nextgen-frontend/shared';
import Footer from '@cfra-nextgen-frontend/shared/src/components/Footer/Footer';
import ErrorBoundary from 'components/fallbacks/ErrorBoundary';
import { TopNavigation } from 'features/topNavigation';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function ProtectedRoutes() {
    const location = useLocation();
    const { route } = useAuthenticator((context) => [context.route]);

    if (route !== 'authenticated') {
        return <Navigate to='/login' state={{ from: location }} replace />;
    }

    return (
        <>
            <TopNavigation />
            <ErrorBoundary FallbackComponent={Fallback.ErrorFallback}>
                <Outlet />
            </ErrorBoundary>
            <Footer />
        </>
    );
}
