import { Button, MaterialIcons } from '@cfra-nextgen-frontend/shared';
import { ETFCard, ItemHeader } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { CarouselTab, CarouselTabs } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFTabPanel';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Avatar, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FiltersForm } from 'features/research/components/FiltersForm';
import { Topic } from '../types/research';

const getScrollButtons = (props: any) => {
    const { direction, ...other } = props;

    if (!other.disabled) {
        return (
            <Button component='div' {...other} sx={{ '&:hover': { backgroundColor: '#FFF' } }} disableRipple>
                <Avatar
                    sx={{
                        backgroundColor: '#3078B5',
                        height: '24px',
                        width: '24px',
                    }}>
                    {direction === 'left' ? (
                        <MaterialIcons.KeyboardArrowLeftRounded sx={{ fontSize: '25px' }} />
                    ) : (
                        <MaterialIcons.KeyboardArrowRightRounded sx={{ fontSize: '25px' }} />
                    )}
                </Avatar>
            </Button>
        );
    } else {
        return <></>;
    }
};

export const Title = styled(ItemHeader)(({ theme }) => ({
    paddingBottom: '10px',
    color: '#0B2958',
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '28px',
    fontStyle: 'normal',
    fontHeight: 500,
    lineHeight: 'normal',
}));

export function Topics({
    allTopics,
    topics,
}: {
    allTopics: Topic[];
    topics: {
        selectedTopics: Array<number>;
        setSelectedTopics: (value: Array<number>) => void;
    };
}) {
    return (
        <ETFCard>
            <Grid container sx={{ padding: '16px 22px' }}>
                <Grid item sm={12}>
                    <Title>Browse by Topics</Title>
                </Grid>
                <Grid item maxWidth={'100%'}>
                    <CarouselTabs
                        aria-label='widget tabs'
                        variant='scrollable'
                        scrollButtons
                        allowScrollButtonsMobile
                        ScrollButtonComponent={(props) => getScrollButtons(props)}
                        value={false}>
                        {allTopics.map((topic, index) => {
                            let { backgroundColor, color } =
                                topics.selectedTopics.findIndex((value) => value === index) !== -1
                                    ? {
                                          backgroundColor: '#007BB8',
                                          color: '#FFF',
                                      }
                                    : { backgroundColor: '#FFF', color: '#57626A' };

                            return (
                                <CarouselTab
                                    key={topic.label}
                                    onClick={() => {
                                        let newTopics: number[] = [];

                                        if (topics.selectedTopics.findIndex((value) => value === index) !== -1) {
                                            newTopics = topics.selectedTopics.filter((topic) => topic !== index);
                                        } else {
                                            newTopics = [...topics.selectedTopics, index];
                                        }
                                        topics.setSelectedTopics(newTopics);
                                    }}
                                    label={topic.label}
                                    icon={
                                        <SvgIcon>
                                            <topic.image />
                                        </SvgIcon>
                                    }
                                    sx={{
                                        border: '1px solid #EDF0F6',
                                        backgroundColor: { backgroundColor },
                                        color: { color },
                                        borderRadius: '6px',
                                        '&:hover': {
                                            backgroundColor: '#007BB8',
                                            color: '#FFF',
                                        },
                                    }}
                                />
                            );
                        })}
                    </CarouselTabs>
                </Grid>
                <FiltersForm />
            </Grid>
        </ETFCard>
    );
}
