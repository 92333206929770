import {
    determineGetData,
    determinePrefetchQuery,
    determineUseData,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiInstanceTypes, ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { API } from 'aws-amplify';

const getData = determineGetData({
    apiNameToApiDetails: {
        [ApiNames.Research]: {
            instance: API,
            instanceType: ApiInstanceTypes.AwsAmplify,
        },
    },
    useDataGetter: (data) => data,
});

export const UseData = determineUseData(getData);
export const prefetchQuery = determinePrefetchQuery(getData);
