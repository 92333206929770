import { Authenticator, Heading, Image, Text, useAuthenticator, useTheme, View, Button } from '@aws-amplify/ui-react';
import WALogo from '../../../assets/images/WA_logo_blue.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ETFPageContainer } from 'components/layout';

export function Login() {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/';
    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);

    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign='center' padding={tokens.space.large}>
                    <Image alt='Amplify logo' src={WALogo} />
                </View>
            );
        },
        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign='center' padding={tokens.space.large}>
                    <Text color={tokens.colors.neutral[80]}>&copy; Footer</Text>
                </View>
            );
        },
        SignIn: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
                        Sign in to your account
                    </Heading>
                );
            },
            Footer() {
                const { toResetPassword } = useAuthenticator();
                //const toResetPasswordCustom = () => {
                //    console.log('CUSTOM RESET');
                //};

                return (
                    <View textAlign='center'>
                        <Button fontWeight='normal' onClick={toResetPassword} size='small' variation='link'>
                            Reset Password
                        </Button>
                    </View>
                );
            },
        },
    };

    const formFields = {
        signIn: {
            username: {
                placeholder: 'Enter Your Username',
                isRequired: true,
                label: 'Username',
            },
        },
    };

    return (
        <ETFPageContainer>
            <Authenticator formFields={formFields} components={components} hideSignUp={true} />
        </ETFPageContainer>
    );
}
