import useMediaQuery from '@mui/material/useMediaQuery';
import { ETFPageContainer } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFPageContainer';
import { Outlet } from 'react-router-dom';
import { Layout } from '@cfra-nextgen-frontend/shared';
import React from 'react';

export function PageWithComponentInHeader({ component, ...props }: { component?: React.ReactNode }) {
    const isScreenDisplay = useMediaQuery('screen');

    return (
        <>
            <Layout.ETFHeaderContainer
                shadow
                sx={{
                    zIndex: 1100,
                    position: isScreenDisplay ? 'sticky' : 'static',
                    top: '72px',
                }}>
                {component}
            </Layout.ETFHeaderContainer>
            <ETFPageContainer>
                <Layout.ETFTabContainer
                    style={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        justifyContent: 'center',
                    }}>
                    <Outlet />
                </Layout.ETFTabContainer>
            </ETFPageContainer>
        </>
    );
}
